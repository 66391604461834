/* @import url(//db.onlinewebfonts.com/c/b0cc4fe77d43f15314c1e266b2d8272d?family=ITC+Clearface+Std); */

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  display: flex;
}
@font-face {
  font-family: 'Clearface ITC Pro';
  src: local('../public/ITCClearfaceStd/ClearfaceStd-Regular.otf')
    url('../public/ITCClearfaceStd/ClearfaceStd-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'ITC Clearface Std';
  src: url('../public/ITCClearfaceStd/b0cc4fe77d43f15314c1e266b2d8272d.eot');
  src: url('../public/ITCClearfaceStd/b0cc4fe77d43f15314c1e266b2d8272d.eot?#iefix') format('embedded-opentype'),
  url('../public/ITCClearfaceStd/b0cc4fe77d43f15314c1e266b2d8272d.woff2') format('woff2'),
  url('../public/ITCClearfaceStd/b0cc4fe77d43f15314c1e266b2d8272d.ttf') format('truetype');
}
highlight {
  font-family: 'Gloria Hallelujah';
  font-weight: normal;
}
details > summary::marker, /* Latest Chrome, Edge, Firefox */ 
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

.accordion > .accordion-title {
  list-style-type: none;
}

.accordion > .accordion-title::-webkit-details-marker {
  display: none;
}
.accordion > .accordion-title::marker {
  display: none;
}

.accordion > .accordion-title::after {
  content: url('./assets/images/down_arrow.svg');
}

.collapsible > .collapsible-title::after {
  background-image: url('./assets/images/down_arrow.svg');
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  content: '';
}
.accordion-title::before {
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.25s transform ease;
}

.accordion[open] > .accordion-title::after {
  content: url('./assets/images/up_arrow.svg');
}

.collapsible[open] > .collapsible-title::after {
  background-image: url('./assets/images/up_arrow.svg');
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  content: '';
}

@keyframes show-Slide {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide {
  animation: show-Slide 1s ease-in-out;
}

.cookie-alert {
  width: 100%;
  max-width: 304px;
  right: 0;
}

.cookie-alert-title {
  margin: 0;
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: 'ITC Clearface Std';
  font-weight: normal;
  font-size: 24px;
}

.cookie-alert-text {
  font-family: 'Inter';
  font-weight: normal;
  font-size: 12px;
}

.cookie-consent-container {
  bottom: 40% !important;
}

.mask {
  background-image: url(./assets/images/play.svg);
}

.mask:hover {
  background-image: url(./assets/images/play_hover.svg);
}
.containerSearchBar {
  @media (min-width: 1024px) {
    max-width: 450px;
  }
}
.containerSearchBar > div {
  position: absolute;
  width: 100%;
  top: 0;
}